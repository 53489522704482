
<script setup>
    import { ref, onMounted, onBeforeUnmount, watch } from "vue";
    import noUiSlider from "mobile.lib.nouislider.js";
    import { hapticsImpact } from "./utils.ts";

    const emit = defineEmits(["update", "update:start", "update:stop"]);
    const props = defineProps({
        "min": [ Number, String ],
        "max": [ Number, String ],
        "start": [ Number, String ],
        "stop": [ Number, String ],
        /*
        "options": { type: Object, default: {
            start: [20, 80],
            connect: true,
            range: {
                "min": 0,
                "max": 100,
            },
        } },
        */
    });

    const el = ref(null);
    let slider = null;

    const options = {
        start: [props.start, props.stop],
        connect: true,
        range: {
            "min": props.min || 0,
            "max": props.max || 100,
        },
    };

    function updateRange() {
        slider.updateOptions({
            range: {
                min: props.min || 0,
                max: props.max || 100,
            }
        });
    }

    function updateStartStop() {
        if (!isNaN(parseFloat(props.start)) && !isNaN(parseFloat(props.stop))) {
            slider.updateOptions({
                start: [
                    props.start || 0,
                    props.stop || 100,
                ],
            });
        }
    }

    watch(() => [ props.min, props.max ], () => {
        //updateStart();
        updateRange();
    });

    watch(() => [ props.start, props.stop ], () => {
        //updateStart();
        updateStartStop();
    });

    function onUpdate(range, index) {
        let old;
        let val;
        if (index === 0) {
            old = props.start;
            val = range[0];
            emit("update:start", range[0]);
        } else if (index === 1) {
            old = props.stop;
            val = range[1];
            emit("update:stop", range[1]);
        }
        old = parseFloat(old);
        val = parseFloat(val);
        if (!isNaN(old) && !isNaN(val)) {
            const old_index = Math.floor((old + 0.01)/0.1);
            const val_index = Math.floor((val + 0.01)/0.1);
            if (old_index !== val_index) {
                hapticsImpact("SelectionClick");
            }
        }
    }

    onMounted(() => {
        slider = noUiSlider.create(el.value, options);

        window.slider = slider;

        slider.on("slide", onUpdate);

        updateStartStop();
    });

    onBeforeUnmount(() => {
        slider.off("slide", onUpdate);

        slider.destroy();
    });
</script>

<template>
    <div ref="el" v-bind="$attrs" class="prevent-swipe">
    </div>
</template>

<style scoped>
</style>
